import http from './http.js'
import { getConfig } from '../config.js'

export function fetchProject(projectName) {
  const config = getConfig()
  const docsApi = config.docsApi
  return http.get(`${docsApi}/projects/${projectName}`)
}

export function fetchDocument(href) {
  return http.get(href)
}
