import React, { useState } from 'react'
import { Select as EDSSelect } from '@nike/eds'

import styles from './editableMultiSelectInput.styl'

export default function EditableMultiSelectInput({ meta, ...props }) {
  const [addedOptions, setAdded] = useState([])
  const onAdd = (value) => {
    value = value.trim()
    const newOption = { value, label: value }
    setAdded((o) => o.concat(newOption))
    props.input.onChange([...props.input.value, value])
  }
  const onChange = (selected) => {
    props.input.onChange(selected?.map((c) => c.value))
  }
  const displayValue = [...props.options, ...addedOptions].filter(({ value }) =>
    props.input.value.includes(value)
  )
  const hasError = meta.error
  return (
    <div>
      <div className={styles.selectContainer}>
        <EDSSelect
          {...props}
          className={`${styles.multiSelectInput} ${hasError ? styles.error : ''} `}
          isCreatable
          isMulti
          blurInputOnSelect
          onBlurResetsInput={false}
          onCreateOption={onAdd}
          value={displayValue}
          onChange={onChange}
          hasErrors={hasError}
          errorMessage={meta.error}
        />
      </div>
    </div>
  )
}
