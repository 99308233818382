import urlJoin from 'url-join'

import http from '../../util/http.js'

export async function getDocumentImage(versionName, projectName, documentName, imagePath, docsApi) {
  const url = urlJoin(
    docsApi,
    'documents',
    projectName,
    versionName,
    documentName,
    'images',
    encodeURIComponent(imagePath)
  )
  return http.getImage(encodeURI(url))
}

export function bufferToBase64(buffer) {
  const bytes = Array.from(new Uint8Array(buffer))
  return window.btoa(bytes.map((b) => String.fromCharCode(b)).join(''))
}

export async function getDocumentImageBase64(
  versionName,
  projectName,
  documentName,
  imagePath,
  docsApi
) {
  try {
    const data = await getDocumentImage(versionName, projectName, documentName, imagePath, docsApi)
    return bufferToBase64(data)
  } catch (error) {
    console.error(error)
  }
}
