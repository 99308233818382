import styles from '../../stylus/doctree.styl'
import React from 'react'
/* eslint-disable-next-line import/no-unresolved */
import { Icon, Spinner } from '@nike/eds'

const decorators = (setToggledState, Link, basePath) => ({
  Loading: () => {
    return (
      <div style={{ fontSize: '14px' }}>
        <Spinner className={styles['tree-spinner']} />
      </div>
    )
  },
  Toggle: (props) => {
    if (!props.children || !props.children.length) {
      return <div style={{ marginLeft: '21px' }} />
    }
    const dir = props.toggled ? 'Down' : 'Right'
    return (
      <div
        onClick={() => {
          setToggledState(props.name)
          props.onClick()
        }}
        className={styles['tree-toggle']}
      >
        <Icon size='small' name={`Caret${dir}`} />
      </div>
    )
  },
  Header: (props) => {
    const className = `${props.selected ? styles['tree-selected'] : ''} eds-type--body-3 ${
      props.searched ? styles['tree-searched'] : ''
    } ${styles['tree-header']}`
    if (props.type === 'heading')
      return (
        <span
          className={className}
          onClick={() => {
            setToggledState(props.name)
            props.onClick && props.onClick()
          }}
        >
          {props.name}
        </span>
      )
    return props.href ? (
      <a target='blank' rel='noreferrer noopener' href={props.href} className={className}>
        {props.name}
      </a>
    ) : (
      <Link href={basePath + props.path} className={className}>
        {props.name}
      </Link>
    )
  },
  Container: (props) => {
    if (!props.node.visible) return null
    return (
      <span className={styles['tree-item']}>
        <props.decorators.Toggle {...props.node} onClick={props.onClick} />
        <props.decorators.Header {...props.node} />
      </span>
    )
  },
})

export default decorators
