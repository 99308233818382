import React, { useEffect, useMemo } from 'react'
import Heading from './Heading.js'
import markdownToAST from '../util/markdownToAst.js'
import pickHeadingFromAST from '../util/pickHeadingFromAst.js'
import parseHeadingAST from '../util/parseHeadingAST.js'
import parseHeadingText from '../util/parseHeadingText.js'

const ReactMarkdownHeading = ({
  markdown,
  ulClassName,
  liClassName,
  anchorClassName,
  hyperlink,
  blankSpaceReplaceText,
  headingDepth = 4,
  hyperLinkPrefix,
  onChangeHeading,
  activeLiClassName,
  activeHash,
}) => {
  const headingAst = pickHeadingFromAST(markdownToAST(markdown), headingDepth)
  const headingList = useMemo(() => parseHeadingAST(headingAst), [headingAst])
  const parsedHeadingList = useMemo(
    () => parseHeadingText(headingList, blankSpaceReplaceText, hyperLinkPrefix),
    [headingList]
  )

  useEffect(() => {
    if (onChangeHeading) {
      onChangeHeading(parsedHeadingList)
    }
  }, [parsedHeadingList])

  return (
    <Heading
      headingList={parsedHeadingList}
      ulClassName={ulClassName}
      liClassName={liClassName}
      anchorClassName={anchorClassName}
      hyperlink={hyperlink}
      activeLiClassName={activeLiClassName}
      activeHash={activeHash}
    />
  )
}

export default ReactMarkdownHeading
