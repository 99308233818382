import http from './http.js'
import { getConfig } from '../config.js'

export async function authDAMSession() {
  const config = getConfig()
  try {
    await http.post(`${config.damAPIUrl}/v1/auth/session`, {}, {}, { credentials: 'include' })
  } catch (error) {
    console.error('Failed to create DAM session:', error)
  }
}
