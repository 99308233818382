import extractText from './extractText.js'

const parseHeadingText = (headingList, blankSpaceReplaceText = '-', hyperlinkPrefix = '') => {
  const parsedHeadingList = []

  headingList.forEach((item) => {
    const { text, href } = extractText(item.children, blankSpaceReplaceText, hyperlinkPrefix)
    const duplicateCount = parsedHeadingList.filter((item) => item.href === href).length

    parsedHeadingList.push({ ...item, text, href, duplicateCount })
  })

  parsedHeadingList.forEach((item) => {
    const { href, duplicateCount } = item
    item.href = href + (duplicateCount ? `${blankSpaceReplaceText}${duplicateCount}` : '')
  })

  return parsedHeadingList
}

export default parseHeadingText
