import styles from './token-modal.styl'
import React, { useState } from 'react'
import { Icon, Button, TextArea, Modal } from '@nike/eds'

export function TokenModal(props) {
  const [token, setToken] = useState()
  const [visible, setVisible] = useState(false)

  const { onChange } = props

  return (
    <div className={styles.tokenModal}>
      <Button afterSlot={<Icon name='Plus' />} onClick={() => setVisible(true)}>
        Set Token
      </Button>
      <Modal
        headerSlot={
          <TextArea
            label='Set OAuth Bearer Token'
            onChange={(e) => setToken(e?.target?.value || '')}
          />
        }
        footerSlot={
          <>
            {' '}
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button
              onClick={() => {
                onChange && onChange(token)
                setVisible(false)
              }}
            >
              Save
            </Button>
          </>
        }
        isOpen={visible}
        onDismiss={() => setVisible(false)}
      ></Modal>
    </div>
  )
}
