import classnames from 'classnames'
import React, { memo, useContext } from 'react'
import { slugify } from '../../../../util/slugify.js'
import { DocsContentCtx } from '../../../Content/Content.js'
import styles from '../../../../stylus/docs.styl'
const Heading = memo(
  ({
    headingList,
    rootId = 0,
    ulClassName,
    liClassName,
    hyperlink = false,
    activeLiClassName,
    activeHash,
  }) => {
    const filteredList = headingList.filter((item) => item.parentId === rootId)
    if (filteredList.length === 0) return null
    const activeIndex = useContext(DocsContentCtx)

    return (
      <ul className={classnames(ulClassName)}>
        {filteredList.map((item, index) => {
          const { text, href } = item
          const isActive = slugify(href.slice(1)) === activeIndex
          return (
            <li key={index} className={classnames(liClassName)}>
              {hyperlink ? (
                <a
                  href={`#${slugify(href.slice(1))}`}
                  className={isActive ? '' : styles.inactiveLink}
                >
                  {text}
                </a>
              ) : (
                text
              )}
              <Heading
                headingList={headingList}
                rootId={item.id}
                ulClassName={ulClassName}
                liClassName={liClassName}
                hyperlink={hyperlink}
                activeHash={activeHash}
                activeLiClassName={activeLiClassName}
              />
            </li>
          )
        })}
      </ul>
    )
  }
)

export default Heading
