export const parse = (api) => {
  if (!api) return null
  if (typeof api === 'object') return api
  if (api[0] === '{') {
    try {
      return JSON.parse(api)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error attempting API JSON parse', e)
    }
  }

  return null
}
