import { NarkPlatform } from '@nike/nark-sdk'
import { getConfig } from '../config.js'

class ConsoleNark extends NarkPlatform {
  log(data) {
    super.log({
      source: 'ui',
      operation: data.operation,
      feature: data.feature,
      data: data.data,
    })
  }
}

function NarkDocs(token) {
  return new ConsoleNark({
    token,
    ...getConfig().nark,
  })
}

export default NarkDocs
