import styles from '../../stylus/doctree.styl'
import React, { useState, useEffect } from 'react'
import { Treebeard } from 'react-treebeard'
import { TextField, Spinner, IconButton, Tooltip } from '@nike/eds'

import { useDebounce } from '../../util/useDebounce.js'
import decorators from './decorators.js'
import treeStyles from './treebeard-style.js'
import http from '../../util/http.js'
import { getConfig } from '../../config.js'
import { slugify } from '../../util/slugify.js'

function Tree({
  pathCrumbs,
  tree,
  setCollapsed,
  collapsed,
  Link,
  basePath,
  projectName,
  projectAlias,
  error,
  docsApi,
  hasTopNav,
  contacts,
}) {
  const config = getConfig()
  const [searchVal, setSearchVal] = useState('')
  const [data, setData] = useState(null)
  const [cursor, setCursor] = useState(false)
  const [searchItems, setSearchItems] = useState([])

  // "toggled" is what treebeard uses to indicate a node is open or closed
  const [toggledState, setToggledState] = useState(
    pathCrumbs.map((crumb) => crumb.replace(/%20/g, ' '))
  )
  const debouncedSearchVal = useDebounce(searchVal, 400)

  const onSearchChange = async (e) => {
    const val = e.target.value
    setSearchVal(val)
    if (!val) return setSearchItems([])
  }

  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false
    }
    node.active = true
    if (node.children) {
      node.toggled = toggled
    }
    setCursor(node)
    setData(Object.assign([], data))
  }

  useEffect(() => {
    async function fetchData(val) {
      try {
        const response = await http.get(`${docsApi}/documents?q=${val}&projectName=${projectName}`)
        setSearchItems(response.items.map((item) => item.title.toUpperCase()))
      } catch (error) {
        console.error(error)
      }
    }

    if (debouncedSearchVal?.length > 1) {
      fetchData(debouncedSearchVal)
    }
  }, [debouncedSearchVal, projectName])

  useEffect(() => {
    if (tree) {
      setData(createTree(pathCrumbs, tree, searchItems, toggledState))
    }
  }, [pathCrumbs, searchItems, tree, toggledState])

  useEffect(() => {
    const element = document.getElementById('docs-content-wrapper')
    if (element) {
      element.scrollTop = 0 // Scroll to the top
    }
  }, [data])

  // Keeps track of open/closed tree items (also looks at url to determine open/closed)
  function setToggledArray(name) {
    const slugName = slugify(name)
    if (toggledState.includes(slugName)) {
      setToggledState(toggledState.filter((item) => slugify(item) !== slugName))
    } else {
      setToggledState([...toggledState, slugName])
    }
  }

  function resetSearch() {
    setSearchItems([])
    setSearchVal('')
  }

  function setCollapsedState(bool) {
    window.localStorage.setItem(config.treeCollapseLocalStorage, bool)
    setCollapsed(bool)
  }

  return collapsed ? (
    <div className={`${styles.collapsed} ${styles.doctree}`}>
      <div className={styles['tree-title']}>
        <IconButton
          onClick={() => setCollapsedState(false)}
          icon='Menu'
          size='small'
          variant='ghost'
        />
      </div>
    </div>
  ) : (
    <div className={`${styles.doctree} ${hasTopNav ? styles.hasTopNav : ''}`}>
      <div className={styles['tree-title']}>
        <IconButton
          onClick={() => setCollapsedState(true)}
          icon='CaretLeft'
          size='small'
          variant='ghost'
        />
        <span className={`${styles['product-name']} eds-type--title-6`}>
          {projectAlias || projectName.replace(/-/g, ' ')}
        </span>
      </div>
      <div className={styles['tree-divider']}></div>
      <div className={styles['tree-search-container']}>
        {!!contacts.length && (
          <div className={styles['utility-links']}>{contacts.map(renderContact)}</div>
        )}
        <TextField
          className={styles['tree-search']}
          aria-label='tree search'
          onChange={onSearchChange}
          value={searchVal}
          placeholder='Search Tree'
          afterSlot={searchVal && <IconButton onClick={resetSearch} icon='Close' variant='ghost' />}
        />
      </div>
      <div className={styles['tree-content']}>
        {error ? null : !data ? (
          <Spinner className={styles['tree-spinner']} />
        ) : (
          <Treebeard
            animations={false}
            style={treeStyles}
            decorators={decorators(setToggledArray, Link, basePath)}
            data={data}
            onToggle={onToggle}
          />
        )}
      </div>
    </div>
  )
}

function createTreeNode(item, toggledArray, path, pathCrumbs, searchItems, selected) {
  // Sets Url path for tree headers
  const _path = path || `/${item.slug}`

  // Item is visible if searched/toggled or children are searched/toggled
  const visible =
    searchItems && searchItems.length
      ? searchItems.filter((searchItem) => shouldNodeBeVisible(item, searchItem)).length
      : true

  const toggled = toggledArray.includes(item.slug) || toggledArray.includes(slugify(item.title))

  const searched =
    searchItems &&
    searchItems.length &&
    searchItems.filter((searchItem) => stringIncludes(item.title, searchItem)).length

  return {
    name: item.title,
    root: !item.parent,
    visible,
    searched,
    path: _path,
    type: item.type,
    href: item.type === 'href' && item.sourceUrl,
    toggled: searchItems?.length ? visible || toggled : toggled,
    selected: selected === item.slug || selected === item.title,

    // Call this function recursively on children
    children: item.children
      ? item.children.map((child) =>
          createTreeNode(
            child,
            toggledArray,
            `${_path}/${child.slug}`,
            pathCrumbs,
            searchItems,
            selected
          )
        )
      : null,
  }
}

// Recursive function
function createTree(pathCrumbs, data, searchVal, toggledArray) {
  const selected = pathCrumbs[pathCrumbs.length - 1]
  return data.map((item) =>
    createTreeNode(item, toggledArray, null, pathCrumbs, searchVal, selected)
  )
}

function shouldNodeBeVisible(obj, search) {
  return (
    stringIncludes(obj.title, search) ||
    obj.children?.some((child) => shouldNodeBeVisible(child, search))
  )
}

function stringIncludes(str, search) {
  return str.toUpperCase().includes(search.toUpperCase())
}

function renderContact(contact) {
  const attr = {
    target: '_blank',
    rel: 'noopener noreferrer',
  }

  return (
    <Tooltip key={contact.label} bodySlot={contact.label} label={contact.label} isDark>
      <a {...attr} href={contact.href}>
        <IconButton
          key={contact.type}
          variant='ghost'
          size='small'
          disableRipple
          icon={<img style={{ verticalAlign: 'top' }} src={contact.src} alt={contact.type} />}
        />
      </a>
    </Tooltip>
  )
}

export default Tree
