import React from 'react'
import { RedocStandalone } from 'redoc'

import { parse as apiParse } from '../../util/api-parser.js'

export function Redoc({ spec, ...props }) {
  const _spec = apiParse(spec)
  return (
    <div className={'redoc-renderer'}>
      <RedocStandalone spec={_spec} options={{ hideDownloadButton: true }} {...props} />
    </div>
  )
}
