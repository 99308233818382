import React, { useState } from 'react'
import { IconButton, Text } from '@nike/eds'
import styles from './manifestBuilder.styl'

function CollapisbleView({ name, children }) {
  const [open, setOpen] = useState(true)
  const handleOpen = (e) => {
    e.preventDefault()
    setOpen(!open)
  }

  return (
    <div className={styles.accordion}>
      <div className={styles.caret}>
        <IconButton
          label='expand'
          icon={open ? 'CaretDown' : 'CaretUp'}
          size='medium'
          onClick={handleOpen}
          variant='ghost'
        />
        <Text font={'title-6'}>{name}</Text>
      </div>

      <div style={{ display: open ? 'block' : 'none' }} className={styles.collapisbleContent}>
        {children}
      </div>
    </div>
  )
}

export default CollapisbleView
