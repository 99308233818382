import styles from '../../stylus/breadcrumbs.styl'

import React from 'react'
import { Link, Text, Select } from '@nike/eds'

export default function({ links, RouterLink }) {
  return (
    <div className={styles['breadcrumb-links']}>
      <div className={styles.links}>
        {links
          .filter((link) => !!link?.title)
          .map(({ title, href }, i) => {
            const link =
              href && i !== links.length - 1 ? (
                <Link as={RouterLink} href={href}>
                  {title}
                </Link>
              ) : (
                title
              )
            return (
              <span key={title + i} className={styles.link}>
                {i > 0 && i <= links.length && <Text className={styles.slash}>/</Text>}
                <Text font='legal-1'>{link}</Text>
              </span>
            )
          })}
      </div>
    </div>
  )
}

export const LinksDropdown = ({ links, onNavigate }) => {
  const options = links.map(({ title, href }) => {
    return {
      label: title,
      value: href,
    }
  })
  return (
    <div className={styles.breadcrumbsDropdown}>
      <Select
        value={options[options.length - 1]}
        className={styles.breadcrumbsSelect}
        onChange={(sel) => {
          if (sel.value) {
            onNavigate(sel.value)
          }
        }}
        options={options}
      />
    </div>
  )
}
