function createDocPath(_path, document, tree, basePath, includeOrigin = false) {
  const documentTitle = document.title
  const pathTitle = document.slug || document.title
  const path = _path ? `${pathTitle}/${_path}` : pathTitle
  const parentTitle = tree[documentTitle]?.parent
  if (parentTitle) {
    const parent = tree[parentTitle]
    return createDocPath(path, parent, tree, basePath, includeOrigin)
  }
  return `${includeOrigin ? window.location.origin : ''}${basePath}/${path}`
}

export default createDocPath
