// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WUGuKqSI3UHJGGvbK4e4{margin:10px 0}.WUGuKqSI3UHJGGvbK4e4 .VQEfnZy1Y3r7_b93fa0w{padding:20px 10px}`, "",{"version":3,"sources":["webpack://./src/components/TokenModal/token-modal.styl"],"names":[],"mappings":"AAAA,sBACE,aAAO,CACP,4CACE,iBAAQ","sourcesContent":[".tokenModal\n  margin 10px 0px\n  .tokenModalContent\n    padding 20px 10px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokenModal": `WUGuKqSI3UHJGGvbK4e4`,
	"tokenModalContent": `VQEfnZy1Y3r7_b93fa0w`
};
export default ___CSS_LOADER_EXPORT___;
