import parseText from './parseText.js'

const extractText = (contents, blankSpaceReplaceText = '-', hyperlinkPrefix = '') => {
  let link = {
    text: '',
    href: '#',
  }

  contents.forEach((content) => {
    link = parseText(content, link, blankSpaceReplaceText, hyperlinkPrefix)
  })

  return link
}

export default extractText
