import React, { useState, useCallback, useMemo } from 'react'
import styles from '../../stylus/docs.styl'
import { IconButton, Select, Tooltip, Chip, Modal, Button, Icon, TextArea, Text } from '@nike/eds'

const Toolbar = React.memo(
  ({
    versions,
    selectedVersion,
    setSelectedVersion,
    toolbar,
    onNavigate,
    favoriteButton,
    onReportClick,
    onReportLink,
    disabled,
    setDisabled,
    onFeedbackSubmit,
    setDisplayThankYouMessage,
  }) => {
    const originalModalState = {
      displayModal: false,
      displayFeedback: false,
      feedbackInput: { rating: null, feedback: '' },
    }
    const [modalState, setModalState] = useState(originalModalState)
    const disabledSubmit =
      !modalState.feedbackInput.feedback || modalState.feedbackInput.rating === null
    const statusExists = useMemo(
      () => !!Object.values(versions)?.some((version) => version?.status),
      [versions]
    )

    const selectLabel = useMemo(
      () =>
        versions[selectedVersion]?.status ? (
          <>
            {selectedVersion} - <Chip size='small'>{versions[selectedVersion]?.status}</Chip>
          </>
        ) : (
          selectedVersion
        ),
      [selectedVersion, versions]
    )

    const handleDismiss = useCallback(() => {
      setModalState(originalModalState)
    }, [])

    const handleProvideFeedback = useCallback(() => {
      setModalState((prev) => ({ ...prev, displayFeedback: true }))
    }, [])

    const handleReportBrokenLink = useCallback(() => {
      setModalState({
        displayModal: false,
        displayFeedback: false,
        feedbackInput: { rating: null, feedback: '' },
      })
      setDisabled(true)
      onReportClick()
    }, [onReportClick, setDisabled])

    const handleFeedbackSubmit = () => {
      onFeedbackSubmit({ ...modalState.feedbackInput, documentUrl: window.location.href })
      setModalState(originalModalState)
      setDisplayThankYouMessage(true)
    }

    const versionOptions = useMemo(
      () =>
        Object.keys(versions).map((ver) => {
          const label = versions[ver]?.status ? (
            <>
              {ver} - <Chip size='small'>{versions[ver]?.status}</Chip>
            </>
          ) : (
            ver
          )
          return { label, value: ver }
        }),
      [versions]
    )

    return (
      <>
        <div className={styles.toolbar}>
          <div className={styles.toolbarActions}>
            {!!onReportLink && (
              <div className={styles.brokenLink}>
                <Tooltip isDark bodySlot={'Provide Feedback'}>
                  <IconButton
                    disabled={disabled}
                    variant='secondary'
                    size='small'
                    icon='AlertCircle'
                    onClick={() => setModalState((prev) => ({ ...prev, displayModal: true }))}
                  />
                </Tooltip>
              </div>
            )}
            {favoriteButton && <div className={styles.favorite}>{favoriteButton}</div>}
          </div>
          {Object.keys(versions).length > 1 && (
            <Select
              subtitle='version'
              value={selectedVersion ? { label: selectLabel, value: selectedVersion } : null}
              className={statusExists ? styles.versionsSelect : ''}
              onChange={(sel) => {
                onNavigate(getRootUrl() + `?version=${sel.value}`)
                setSelectedVersion(sel.value)
              }}
              options={versionOptions}
            />
          )}
          {toolbar}
        </div>
        <FeedbackModal
          modalState={modalState}
          setModalState={setModalState}
          disabledSubmit={disabledSubmit}
          handleDismiss={handleDismiss}
          handleProvideFeedback={handleProvideFeedback}
          handleReportBrokenLink={handleReportBrokenLink}
          handleFeedbackSubmit={handleFeedbackSubmit}
        />
      </>
    )
  }
)

function getRootUrl() {
  return window.location.origin + window.location.pathname
}

const FeedbackModal = ({
  modalState,
  setModalState,
  disabledSubmit,
  handleDismiss,
  handleProvideFeedback,
  handleReportBrokenLink,
  handleFeedbackSubmit,
}) => (
  <Modal
    headerSlot={modalState.displayFeedback ? 'Was this content helpful?' : 'Help us improve!'}
    footerSlot={
      <div className={styles.feedbackModal}>
        {modalState.displayFeedback && (
          <Button onClick={handleFeedbackSubmit} disabled={disabledSubmit}>
            Submit Feedback
          </Button>
        )}
        <Button variant='secondary' onClick={handleDismiss}>
          Close
        </Button>
      </div>
    }
    isOpen={modalState.displayModal}
    onDismiss={handleDismiss}
  >
    {!modalState.displayFeedback && (
      <div className={styles.feedbackModalActionButtons}>
        <Button onClick={handleProvideFeedback}>Provide Feedback</Button>
        <Button onClick={handleReportBrokenLink}>Report Broken Link</Button>
      </div>
    )}
    {modalState.displayFeedback && (
      <FeedbackForm modalState={modalState} setModalState={setModalState} />
    )}
  </Modal>
)

const FeedbackForm = ({ modalState, setModalState }) => (
  <div className={styles.feedbackForm}>
    <div className={styles.feedbackFormIcons}>
      <FeedbackIcon type='positive' modalState={modalState} setModalState={setModalState} />
      <FeedbackIcon type='negative' modalState={modalState} setModalState={setModalState} />
    </div>
    <TextArea
      placeholder='How can we make it better?'
      onChange={(e) => {
        setModalState((prev) => ({
          ...prev,
          feedbackInput: { ...prev.feedbackInput, feedback: e.target.value },
        }))
      }}
      minRows={3}
    />
  </div>
)

const FeedbackIcon = ({ type, modalState, setModalState }) => {
  const types = {
    positive: {
      icon: 'ThumbsUp',
      color: 'var(--eds-color-text-success)',
      rating: 1,
      label: 'Yes',
    },
    negative: {
      icon: 'ThumbsDown',
      color: 'var(--eds-color-text-danger)',
      rating: 0,
      label: 'No',
    },
  }
  const currentRating = modalState.feedbackInput.rating
  const { icon, rating, color, label } = types[type]

  const getColor = () => {
    if (currentRating === null) return
    if (currentRating === 1 && type === 'positive') return color
    if (currentRating === 0 && type === 'negative') return color
  }

  return (
    <div className={styles.feedbackIcon}>
      <Icon
        className={styles[`feedbackIcon${icon}`]}
        name={icon}
        size='l'
        color={getColor()}
        onClick={() =>
          setModalState((prev) => ({
            ...prev,
            feedbackInput: { ...prev.feedbackInput, rating },
          }))
        }
      />
      <Text>{label}</Text>
    </div>
  )
}

export default Toolbar
