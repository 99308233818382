import React, { useMemo } from 'react'
import DocumentationManifestForm from './DocumentationManifestForm.js'

// REQUIRED
// - manifest - manifest version.  This is added by us to the data.  Not part of form.
// - title
// - type - hardcoded by us.  Should be 'documentation' for now.  Expand to different types of manifests in the future (documentation, api, library, notebook)
// - maintainerEmail - mail address of the primary contact for the project
// - defaultVersion - must specify a default version.  Default this to the only version if only 1 version
// - versions - must have at least 1 version

const convertVersionsToObject = (obj) => {
  let result = {}
  obj.map((o) => {
    const { environments } = o
    const key = o.name
    delete o.name
    result[key] = o
    if (environments) {
      if (environments.length > 0) {
        const env = convertVersionsToObject(environments)
        result[key].environments = env
      } else {
        // dont save empty array to environments
        delete result[key].environments
      }
    }
    return o
  })
  return result
}
const convertUrls = (urls) => {
  const parsed = {
    ...urls,
  }
  // if there are sdks
  if (parsed.sdk) {
    // filter out the default undefined value
    parsed.sdk = parsed.sdk?.filter(Boolean)
    // if there no valid sdks, remove the value
    if (!parsed.sdk.length) {
      delete parsed.sdk
    }
  }
  return parsed
}

const ManifestBuilder = ({
  onSubmit,
  onCancel,
  type = 'documentation',
  tags,
  manifestJSON,
  isPlatformApi,
  capabilities,
  owners = [],
  loading,
  isNew,
}) => {
  const manifestData = useMemo(() => {
    let manifest = manifestJSON
    if (manifest) {
      if (typeof manifestJSON === 'string') {
        manifest = JSON.parse(manifestJSON)
      }

      if (manifest.versions) {
        const versions = Object.keys(manifest.versions).map((versionName) => ({
          ...manifest.versions[versionName],
          name: versionName,
          environments: Object.keys(manifest.versions[versionName].environments || []).map(
            (envName) => ({
              ...manifest.versions[versionName].environments[envName],
              name: envName,
            })
          ),
        }))
        manifest = {
          ...manifest,
          versions,
        }
      }
    }

    return manifest
  }, [manifestJSON])

  const handleSubmit = (values, form) => {
    // Because the from renders the values, so we can't delete the original value, hence the cloning values and must shallow copy.
    const clonedValues = JSON.parse(JSON.stringify(values))

    const { versions, defaultVersionIndex } = clonedValues
    const parsedVersion = convertVersionsToObject(versions)
    const index = defaultVersionIndex || 0
    const defaultVersion = values.versions[index].name

    const submitValues = {
      ...values,
      urls: convertUrls(values.urls),
      defaultVersion,
      versions: parsedVersion,
      type,
      manifest: '1.2',
    }

    // if there are no urls, dont set urls property
    if (!Object.keys(submitValues.urls).length) {
      delete submitValues.urls
    }

    // this is only for the form
    delete submitValues.defaultVersionIndex

    onSubmit && onSubmit(submitValues, form)
  }

  const handleCancel = (isDirty) => onCancel && onCancel(isDirty)

  switch (type) {
    case 'documentation':
      return (
        <DocumentationManifestForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          tags={tags}
          manifestJSON={manifestData}
          isPlatformApi={isPlatformApi}
          capabilities={capabilities}
          owners={owners}
          loading={loading}
          isNew={isNew}
        />
      )
    default:
      console.log('Unsupported manifest type:', type)
      return null
  }
}

export default React.memo(ManifestBuilder)
