import { labels } from './constants.js'

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const required = (value) => {
  return value ? undefined : 'Required'
}

export const isValidURL = (url) => {
  if (!url) return undefined
  let expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
  let regex = new RegExp(expression)

  if (url.match(regex)) {
    return undefined
  }
  return 'Invalid URL'
}

export const noSpecialChars = (value) => {
  const regexp = new RegExp(/^[^;/?:@=&"'<>#%{}|\\^~[\]`]+$/g)
  return regexp.test(value)
    ? undefined
    : 'Must not contain / ? : @ = & " \' < > # % { } | \\ ^ ~ [ ]'
}

export const maxItems = (count) => (value) =>
  value?.length > count && `Total items cannot exceed ${count}`

export const itemSizeLimit = (count) => (values) => {
  const tooLong = values?.filter((value) => value.length > count)
  if (tooLong?.length) {
    return `An item cannot exceed ${count} characters`
  }
}

export const atLeastOneRequired = (errorMessage = 'At least 1 required.') => (arr) => {
  return arr?.length ? undefined : errorMessage
}

export const chronologicalOrder = (dates) => {
  let outOfOrder = false
  dates = dates.filter(Boolean)
  dates.sort((a, b) => {
    if (new Date(a).getTime() < new Date(b).getTime()) {
      outOfOrder = true
    }
    return new Date(a).getTime() < new Date(b).getTime()
  })
  return dates && outOfOrder ? 'Dates must be in chronological order.' : undefined
}

export const checkIfOpenApiTypeExists = (documents) => {
  return documents && documents.filter((d) => d?.type === 'openapi').length > 0
    ? undefined
    : labels.docTypeSubtitle
}

export const checkIfRawGit = (url) => {
  const r = /^https:\/\/raw.githubusercontent.com\/(.*)$$/
  if (r.test(url)) return undefined
  return 'URL must be a raw GitHub URL'
}
