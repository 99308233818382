/* eslint-disable-next-line import/no-mutable-exports */
import merge from 'deepmerge'

// Console API
const platformConsoleApi = (env) =>
  env
    ? `https://api-${env}-console.platforms.nike.com/graphql`
    : 'https://api-console.platforms.nike.com/graphql'

const narkPreprod = {
  platformId: '==bjBjhWpa9HxNExASJu2Dl',
  url: 'https://api-preprod.nark.nikecloud.com/v1',
}
const narkProd = {
  platformId: '==K_YcMRW1x_0wC-unVh--w',
  url: 'https://api.nark.nikecloud.com/v1',
}

const base = {
  treeCollapseLocalStorage: 'EPDOCS-TREE-COLLAPSED',
  githubCloudProxyHost: 'raw.githubusercontent.com',
  damAPIUrl: 'https://api.preprod.dam.nike.com',
  nark: narkPreprod,
  platformConsoleApi: platformConsoleApi('dev'),
}

const dev = merge(base, {
  docsApi: window.localStorage.DOCS_CLIENT_URL || 'https://api-developer-dev.lab.niketech.com/v1',
  docProjectBaseUrl:
    window.localStorage.DOCS_PROJECT_BASE_URL ||
    'https://console-dev.platforms.nike.com/developer/docs/projects', // no dev docs site
})

const preprod = merge(base, {
  docsApi: 'https://api-developer.preprod.niketech.com/v1',
  platformConsoleApi: platformConsoleApi('nonprod'),
  docProjectBaseUrl: 'https://docs-nonprod.platforms.nike.com',
})

const prod = merge(base, {
  docsApi: 'https://api-developer.niketech.com/prod/v1',
  damAPIUrl: 'https://api.dam.nike.com',
  platformConsoleApi: platformConsoleApi(),
  nark: narkProd,
  docProjectBaseUrl: 'https://docs.platforms.nike.com',
})

const isProd = () =>
  window.location.origin.includes('//docs.platforms.nike.com') ||
  window.location.origin.includes('//console.platforms.nike.com')

const isDev = () =>
  window.location.origin.includes('//localhost') ||
  window.location.origin.includes('//console-dev.platforms.nike.com')

let config = dev

export const setConfig = (env) => {
  if (env === 'prod') {
    config = prod
  } else if (env === 'preprod') {
    config = preprod
  } else if (env === 'dev') {
    config = dev
  } else if (isProd()) {
    config = prod
  } else if (isDev()) {
    config = dev
  } else {
    config = preprod
  }

  return config
}

export const getConfig = () => config
