// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c_KJl0GMiFdQURVTEJtc{display:flex}.c_KJl0GMiFdQURVTEJtc .xa9cKTkm0JzqmAK3so0b{flex:1}.c_KJl0GMiFdQURVTEJtc .MaxD4JFOBBUDZt3E0i2A{display:flex;flex:1;align-items:center;justify-content:center}.c_KJl0GMiFdQURVTEJtc .mma3nlrQIqBBO0l7xrSb{display:flex;flex-direction:column;width:500px;height:100%}.c_KJl0GMiFdQURVTEJtc .eds-side-panel__content{flex:1}.c_KJl0GMiFdQURVTEJtc .eds-side-panel__content >div{overflow-y:auto}.c_KJl0GMiFdQURVTEJtc .kJ2_VyEveyQ4FMQFZZ_6{margin-top:32px}.c_KJl0GMiFdQURVTEJtc .kJ2_VyEveyQ4FMQFZZ_6 .WA0jhykeIXo5un_m6eWg{margin-bottom:12px}.c_KJl0GMiFdQURVTEJtc .kJ2_VyEveyQ4FMQFZZ_6 ul{list-style:revert;padding-left:30px}.c_KJl0GMiFdQURVTEJtc .kJ2_VyEveyQ4FMQFZZ_6 li{margin-bottom:6px}`, "",{"version":3,"sources":["webpack://./src/stylus/contextualHelp.styl"],"names":[],"mappings":"AACA,sBACE,YAAQ,CACR,4CACE,MAAK,CACP,4CACE,YAAQ,CACR,MAAK,CACL,kBAAY,CACZ,sBAAgB,CAClB,4CACE,YAAQ,CACR,qBAAe,CACf,WAAM,CACN,WAAO,CAET,+CACE,MAAK,CACL,oDACE,eAAW,CAEf,4CACE,eAAW,CACX,kEACE,kBAAc,CAChB,+CACE,iBAAW,CACX,iBAAa,CACf,+CACE,iBAAc","sourcesContent":["\n.contextualHelp\n  display flex\n  .footer\n    flex 1\n  .spinner\n    display flex\n    flex 1\n    align-items center\n    justify-content center\n  .panel\n    display flex\n    flex-direction column\n    width 500px\n    height 100%\n\n  :global(.eds-side-panel__content)\n    flex 1\n    >div\n      overflow-y auto\n\n  .moreLinks\n    margin-top 32px\n    .moreLinksHeader\n      margin-bottom 12px\n    ul\n      list-style revert\n      padding-left 30px\n    li\n      margin-bottom 6px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextualHelp": `c_KJl0GMiFdQURVTEJtc`,
	"footer": `xa9cKTkm0JzqmAK3so0b`,
	"spinner": `MaxD4JFOBBUDZt3E0i2A`,
	"panel": `mma3nlrQIqBBO0l7xrSb`,
	"moreLinks": `kJ2_VyEveyQ4FMQFZZ_6`,
	"moreLinksHeader": `WA0jhykeIXo5un_m6eWg`
};
export default ___CSS_LOADER_EXPORT___;
