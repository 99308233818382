import React, { useCallback, useState } from 'react'
import { GraphiQL } from 'graphiql'
import 'graphiql/graphiql.css'
import http from '../../util/http.js'
import { TokenModal } from '../TokenModal/token-modal.js'

const defaultStyle = { height: 'calc(100vh - 100px)' }

export default function Graphiql({ apiUrl, token: defaultToken, style, includeBearer = false }) {
  const [token, setToken] = useState(defaultToken)
  const [key, setKey] = useState(Date.now())
  const activeStyle = style || defaultStyle
  const fetcher = useCallback(
    (params) =>
      http
        .post(apiUrl, params, {
          Authorization: includeBearer ? `Bearer ${token}` : token,
        })
        .catch((e) => e),
    [apiUrl, token, includeBearer]
  )

  return (
    <div style={activeStyle}>
      <TokenModal
        onChange={(t) => {
          setToken(t)
          setKey(Date.now)
        }}
      />
      <GraphiQL key={key} fetcher={fetcher} />
    </div>
  )
}
