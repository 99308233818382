const labels = {
  title: 'Name',
  description: 'Description',
  maintainerEmail: 'Maintainer Email',
  tags: 'Tags',
  repositoryUrl: 'Code Repository URL',
  issuesUrl: 'Issue Tracker URL',
  slackUrl: 'Slack Channel URL',
  terraformUrl: 'Terraform Provider URL',
  removeSdkUrl: 'Remove SDK URL',
  sdkUrlName: 'SDK Name',
  sdkUrl: 'SDK URL',
  cliUrl: 'CLI URL',
  projectDocumentationUrls: 'Project Documentation URLs',
  addAnotherSdk: 'Add Another SDK URL',
  versions: 'Versions',
  status: 'Status',
  location: 'Location',
  defaultVersion: 'Default Version',
  url: 'URL',
  type: 'Type',
  untitledDocument: 'Untitled Document',
  addDocument: 'Add Document',
  addNewVersion: 'Add Another Version',
  removeDocument: 'Remove Document',
  removeVersion: 'Remove Version',
  capabilities: 'Capabilities',
  lifeCycleStatus: 'Lifecycle Status',
  interactionType: 'Interaction Type',
  versionName: 'Version Name',
  document: 'Document',
  documents: 'Documents',
  environment: 'Environment',
  environments: 'Environments',
  noEnvironments: 'No Environments',
  removeEnvironment: 'Remove Environment',
  addEnvironment: 'Add Environment',
  untitledEnvironment: 'Untitled Environment',
  addChildDocument: 'Add Child Document',
  addAnotherDocument: 'Add Another Document',
  addAnotherEnvironment: 'Add Another Environment',
  apiUrl: 'API URL',
  healthUrl: 'Health URL',
  owner: 'Owner',
  apiName: 'API Name',
  capabilitiesError: 'At least 1 platform capability is required.',
  lifecycleError: 'At least 1 lifecycle date is required.',
  lifecycleSubtitle:
    'At least 1 lifecycle date is required. Lifecycles are not active unless a date is selected.',
  lifecycleStatusSubtitles: {
    draft: 'API design is being explored and subject to breaking changes.',
    published: 'API is stable. Future changes cannot break compatibility.',
    deprecated:
      'API is still available, but should not be used or implemented by anything new.  Existing users should plan to use something newer.',
    retired:
      'API should no longer be in use.  Users should already be using a newer API.  Implementations can be divested or removed from code.',
  },
  projectDocumentationUrlsSubtitles: (type = 'project') =>
    `Links to these documents will appear in the header of your ${type} documentation.`,
  docTypeSubtitle: 'Each version must have at least 1 OpenApi document.',
}

const placeholders = {
  capabilities: 'Select 1 or more capabilities of the API',
  lifeCycleStatus: 'Select a lifecycle for the API',
  interactionType: 'Select an interaction type for the API',
  tags: 'Select 1 or more tags',
  versionStatus: 'Select a version status',
  location: 'Select a location',
  docType: 'Select a document type',
  owner: 'Select an owner',
}

const options = {
  versionStatus: [
    {
      value: 'deprecated',
      label: 'Deprecated',
    },
    {
      value: 'develop',
      label: 'Develop',
    },
    {
      value: 'release',
      label: 'Release',
    },
    {
      value: 'design',
      label: 'Design',
    },
    {
      value: 'beta',
      label: 'Beta',
    },
    {
      value: 'removed',
      label: 'Removed',
    },
  ],

  versionLocations: [
    'us-east-2',
    'us-east-1',
    'us-west-1',
    'us-west-2',
    'af-south-1',
    'ap-east-1',
    'ap-south-1',
    'ap-northeast-3',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ca-central-1',
    'cn-north-1',
    'cn-northwest-1',
    'eu-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-west-3',
    'eu-north-1',
    'eu-south-1',
    'me-south-1',
    'sa-east-1',
    'us-gov-west-1',
    'us-gov-east-1',
    'eastus',
    'eastus2',
    'southcentralus',
    'westus2',
    'westus3',
    'australiaeast',
    'southeastasia',
    'northeurope',
    'swedencentral',
    'uksouth',
    'westeurope',
    'centralus',
    'northcentralus',
    'westus',
    'southafricanorth',
    'centralindia',
    'eastasia',
    'japaneast',
    'jioindiawest',
    'koreacentral',
    'canadacentral',
    'francecentral',
    'germanywestcentral',
    'norwayeast',
    'switzerlandnorth',
    'uaenorth',
    'brazilsouth',
    'centralusstage',
    'eastusstage',
    'eastus2stage',
    'northcentralusstage',
    'southcentralusstage',
    'westusstage',
    'westus2stage',
    'asia',
    'asiapacific',
    'australia',
    'brazil',
    'canada',
    'europe',
    'france',
    'germany',
    'global',
    'india',
    'japan',
    'korea',
    'norway',
    'southafrica',
    'switzerland',
    'uae',
    'uk',
    'unitedstates',
    'unitedstateseuap',
    'eastasiastage',
    'southeastasiastage',
    'centraluseuap',
    'eastus2euap',
    'westcentralus',
    'southafricawest',
    'australiacentral',
    'australiacentral2',
    'australiasoutheast',
    'japanwest',
    'jioindiacentral',
    'koreasouth',
    'southindia',
    'westindia',
    'canadaeast',
    'francesouth',
    'germanynorth',
    'norwaywest',
    'switzerlandwest',
    'ukwest',
    'uaecentral',
    'brazilsoutheast',
    'onprem',
    'saas',
    'cots',
  ],

  versionDocumentTypes: [
    'markdown',
    'openapi',
    'graphql',
    'appSync',
    'redoc',
    'jupyter',
    'asciidoc',
    'href',
    'heading',
  ],

  lifeCycles: [
    {
      value: 'design',
      label: 'Design',
    },
    {
      value: 'development',
      label: 'Development',
    },
    {
      value: 'release',
      label: 'Release',
    },

    {
      value: 'deprecated',
      label: 'Deprecated',
    },
    {
      value: 'removed',
      label: 'Removed',
    },
  ],
  interactionType: [
    {
      value: 'Request Response Non Blocking',
      label: 'Request Response Non Blocking',
    },
    {
      value: 'Request Response Blocking',
      label: 'Request Response Blocking',
    },
    {
      value: 'Request Events',
      label: 'Request Events',
    },
    {
      value: 'Response Events',
      label: 'Response Events',
    },
    {
      value: 'Broadcast Events',
      label: 'Broadcast Events',
    },
  ],
}

export { labels, options, placeholders }
