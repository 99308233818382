import React, { useRef, useEffect } from 'react'
import { render } from 'react-dom'

import Error from '../Error/Error.js'

// Shadow Dom prevents CSS inheritance, we need to manually override them inside the <root> tag
const styleOverride = `
  #notebook-container {
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }
  div#notebook {
    padding-top: 0;
  }
  .prompt {
    min-width: 10ex;
  }
  #notebook .container {
    margin-right: 0;
    margin-left: 0;
    padding: 0 40px 30px 10px;
  }
`

export default function Notebook({ html }) {
  if (!html || !isSafeNotebookHtml(html)) {
    return <Error error={'Notebook contains unsafe HTML'} />
  }

  const ref = useRef()

  useEffect(() => {
    const host = ref.current
    const shadow = host.attachShadow({
      mode: 'open',
    })

    const renderIn = document.createElement('div')
    shadow.appendChild(renderIn)

    if (html) {
      render(
        <>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <style type='text/css'>{styleOverride}</style>
        </>,
        renderIn
      )
    }
  }, [html])

  return <div ref={ref}></div>
}

const whitelistedScripts = [
  'https://cdnjs.cloudflare.com/ajax/libs/require.js/2.1.10/require.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.0.3/jquery.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/latest.js?config=TeX-AMS_HTML',
]

const whiteListedMathScript = String.raw`<script type="text/x-mathjax-config"> MathJax.Hub.Config({ tex2jax: { inlineMath: [ ['$','$'], ["\\(","\\)"] ], displayMath: [ ['$$','$$'], ["\\[","\\]"] ], processEscapes: true, processEnvironments: true }, // Center justify equations in code and markdown cells. Elsewhere // we use CSS to left justify single line equations in code cells. displayAlign: 'center', "HTML-CSS": { styles: {'.MathJax_Display': {"margin": 0}}, linebreaks: { automatic: true } } }); </script>`

function isSafeNotebookHtml(html) {
  let whitelistedHtml = whitelistedScripts.reduce((current, script) => {
    return current.replace(`<script src="${script}"></script>`, '')
  }, html)

  // remove line breks and normalize to a single space
  whitelistedHtml = whitelistedHtml.replace(/\r?\n/g, '').replace(/ +(?= )/g, '')

  whitelistedHtml = whitelistedHtml.replace(whiteListedMathScript, '')

  return !whitelistedHtml.includes('<script')
}
