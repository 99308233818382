export default function scrollIntoView(eleID) {
  let e = document.getElementById(eleID)
  if (!!e && e.scrollIntoView) {
    e.scrollIntoView(true)
  }
}

export function scrollTop(eleID) {
  let e = document.getElementById(eleID)
  if (e) {
    e.scrollTop = 0
  }
}

export function scrollHashIntoView(hash) {
  let targetHash = hash || document.location.hash
  if (targetHash) scrollIntoView(targetHash.substring(1))
}
