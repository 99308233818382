const parseHeadingAST = (headingAST) => {
  const headingList = []

  let depth
  let parentId = 0
  let heading

  headingAST.forEach((item, index) => {
    heading = Object.assign(item, { id: index + 1, parentId: 0 })

    const sames = headingList.filter((itemWithId) => itemWithId.depth === item.depth)
    heading.parentId = item.depth > depth ? index : sames.length && sames[sames.length - 1].parentId
    parentId = item.depth > depth ? parentId + 1 : sames.length && heading.parentId
    depth = item.depth

    headingList.push(heading)
  })

  return headingList
}

export default parseHeadingAST
