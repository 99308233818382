import React, { useState, useEffect, useMemo } from 'react'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'

import {
  Button,
  ButtonGroup,
  Card,
  IconButton,
  Label,
  Text,
  TextArea,
  TextField,
  Tooltip,
  Select,
  Checkbox,
  Spinner,
  Icon,
  Popover,
  Link,
} from '@nike/eds'

import EditableMultiSelectInput from './EditableMultiSelectInput.js'
import CollapisbleView from './CollapisbleView.js'
import { labels, options, placeholders } from './constants.js'
import {
  composeValidators,
  required,
  isValidURL,
  noSpecialChars,
  maxItems,
  itemSizeLimit,
  atLeastOneRequired,
  chronologicalOrder,
  checkIfOpenApiTypeExists,
  checkIfRawGit,
} from './validation.js'

import styles from './manifestBuilder.styl'
import http from '../../util/http.js'

const OPTIONS_DOC = options.versionDocumentTypes.sort().map((value) => ({ value, label: value }))

const OPTIONS_VERSION_LOCATION = options.versionLocations
  .sort()
  .map((value) => ({ value, label: value }))

const getLabel = (props) => {
  let label = `${props.label}`
  // append required asterisk
  if (props.required) {
    label = (
      <>
        {label}
        <span className={'eds-color--danger'}>*</span>
      </>
    )
  }

  return label
}

const getSubTitle = (props) => {
  if (props.subtitle === undefined) return
  let subtitle = `${props.subtitle}`
  // append required asterisk
  if (props.required && props.subtitle) {
    subtitle = (
      <>
        {subtitle}
        <span className={'eds-color--danger'}>*</span>
      </>
    )
  }

  return subtitle
}
function getConditionalLabel(isPlatformApi, label) {
  return isPlatformApi ? `API ${label}` : label
}

function getAddButtonLabel(count, type) {
  let label = type === 'doc' ? labels.addDocument : labels.addEnvironment

  if (count > 0) label = type === 'doc' ? labels.addAnotherDocument : labels.addAnotherEnvironment

  return label
}

function getSelectedOption(options, value) {
  const selected = options?.find((o) => o.value === value)
  return selected
    ? {
        label: selected.label,
        value: selected.value,
      }
    : ''
}

// GENERIC COMPONENTS
function Buttons({ handleSubmit, onCancel, loading, dirty, isNew, isPlatformApi }) {
  let submitLabel = isNew ? 'Create Manifest' : 'Update Manifest'
  let submitExternalId = isNew ? 'create-manifest' : 'update-manifest'

  if (isPlatformApi) {
    submitLabel = isNew ? 'Create API' : 'Update API'
    submitExternalId = isNew ? 'create-api' : 'update-api'
  }

  return (
    <ButtonGroup>
      <Button
        onClick={handleSubmit}
        type='Submit'
        name={submitLabel}
        data-external-id={submitExternalId}
      >
        {submitLabel}
        {loading && (
          <div className='eds--dark'>
            <Spinner />
          </div>
        )}
      </Button>
      <Button variant='secondary' onClick={() => onCancel(dirty)}>
        Cancel
      </Button>
    </ButtonGroup>
  )
}

function TextFieldInput({ input, meta, ...rest }) {
  return (
    <TextField
      {...rest}
      {...input}
      label={getLabel(rest)}
      subtitle={getSubTitle(rest)}
      hasErrors={meta.error && meta.touched}
      errorMessage={meta.error}
    />
  )
}

function TextAreaInput({ input, meta, ...rest }) {
  return (
    <TextArea
      {...rest}
      {...input}
      label={getLabel(rest)}
      subtitle={getSubTitle(rest)}
      hasErrors={meta.error && meta.touched}
      errorMessage={meta.error}
    />
  )
}

function SelectInput({ meta, ...props }) {
  const [value, setValue] = useState(getSelectedOption(props.options, props.input.value))
  const hasError = meta.error && meta.touched

  const onChange = (selected) => {
    setValue(selected)
    props.input.onChange(selected.value)
  }
  useEffect(() => {
    if (meta.initial) {
      props.input.onChange(meta.initial)
    }
  }, [meta.initial])

  return (
    <Select
      {...props}
      options={props.options}
      className={`${hasError ? styles.error : ''} `}
      hasErrors={hasError}
      errorMessage={meta.error}
      blurInputOnSelect
      onBlurResetsInput={false}
      onChange={onChange}
      onBlur={(event) => props.input.onBlur(event)}
      label={
        <>
          {props.label}
          {props.required && <span className={styles.required}>*</span>}
        </>
      }
      subtitle={
        props.subtitle && (
          <>
            {props.subtitle}
            {props.required && <span className={styles.required}>*</span>}
          </>
        )
      }
      defaultValue={meta.initial}
      value={value}
    />
  )
}

function EnvironmentView({ name, push, count, isPlatformApi }) {
  return (
    <div>
      <Label className={styles.versionSectionTitle} font='title-6'>
        {labels.environments}
      </Label>
      {!isPlatformApi && count === 0 ? (
        <div className={styles.noEnvironments}>
          <Text font='subtitle-1'>{labels.noEnvironments}</Text>
          <Button className={styles.addNewButton} size='small' onClick={() => push(name, {})}>
            {getAddButtonLabel(count, 'env')}
          </Button>
        </div>
      ) : (
        <>
          <FieldArray name={name}>
            {({ fields }) =>
              fields.map((name, index) => (
                <div key={name}>
                  <div className={styles.titleWrapper}>
                    <Label font='title-6'>
                      {labels.environment} {`#${index + 1}`}
                    </Label>

                    {(!isPlatformApi || fields.length > 1) && (
                      <div className={styles.buttonWrapper}>
                        <Tooltip
                          isDark
                          label={labels.removeEnvironment}
                          role='tooltip'
                          aria-hidden='true'
                          bodySlot={
                            <div className={styles.tooltip}>{labels.removeEnvironment}</div>
                          }
                        >
                          <IconButton
                            label={labels.removeEnvironment}
                            className={styles.removeButton}
                            variant='secondary'
                            size='small'
                            icon='Minus'
                            onClick={() => fields.remove(index)}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className={styles.nestedWrapper}>
                    <Field
                      required
                      component={TextFieldInput}
                      validate={required}
                      subtitle={labels.title}
                      aria-label={labels.title}
                      name={`${name}.name`}
                      hideLabel
                    />
                  </div>
                  <div className={styles.nestedWrapper}>
                    <Field
                      hideLabel
                      component={TextFieldInput}
                      validate={composeValidators(required, isValidURL)}
                      subtitle={labels.apiUrl}
                      aria-label={labels.apiUrl}
                      name={`${name}.url`}
                      required
                    />
                  </div>
                  <div className={styles.nestedWrapper}>
                    <Field
                      hideLabel
                      required
                      component={SelectInput}
                      options={OPTIONS_VERSION_LOCATION}
                      subtitle={labels.location}
                      aria-label={labels.location}
                      name={`${name}.location`}
                      className={styles.nestedWrapper}
                      placeholder={placeholders.location}
                      validate={required}
                    />
                  </div>
                  <ApiHealthField field={fields.value[index]} name={name} />
                  <br />
                </div>
              ))
            }
          </FieldArray>
          <div className={styles.addNewButton}>
            <Button size='small' variant='secondary' onClick={() => push(name, {})}>
              {getAddButtonLabel(count, 'env')}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

function DocumentView({ name, push, isParent, count, isPlatformApi, allDocuments }) {
  const documentCount = (name.match(/documents/g) || []).length
  const canAddNested = documentCount < 3

  return (
    <div>
      {isParent && (
        <Label className={styles.versionSectionTitle} font='title-6'>
          {labels.documents}
        </Label>
      )}

      {!isParent && canAddNested && (
        <div className={styles.buttonWrapper}>
          <Button size='small' variant='secondary' onClick={() => push(name, {})}>
            {labels.addChildDocument}
          </Button>
        </div>
      )}

      <FieldArray name={name}>
        {({ fields, meta }) =>
          fields.map((name, index) => (
            <div key={name} className={styles.docConetnt}>
              <CollapisbleView
                name={
                  fields.value[index]?.title ? fields.value[index]?.title : labels.untitledDocument
                }
              >
                <div style={{ marginLeft: '40px' }}>
                  <div className={styles.docWrapper}>
                    <Field
                      required
                      hideLabel
                      component={TextFieldInput}
                      validate={required}
                      subtitle={labels.title}
                      aria-label={labels.title}
                      name={`${name}.title`}
                    />
                  </div>
                  <div className={styles.docWrapper}>
                    <Field
                      component={SelectInput}
                      options={OPTIONS_DOC}
                      subtitle={labels.type}
                      aria-label={labels.type}
                      name={`${name}.type`}
                      validate={required}
                      required
                      hideLabel
                      placeholder={placeholders.docType}
                    />
                    {isPlatformApi === true && (
                      <div className={styles.metaError}>
                        {checkIfOpenApiTypeExists(allDocuments) &&
                          (meta.dirty || meta.submitFailed) && (
                            <Text font={'body-3'} className={styles.error}>
                              {checkIfOpenApiTypeExists(allDocuments)}
                            </Text>
                          )}
                      </div>
                    )}
                  </div>
                  <div className={styles.docWrapper}>
                    {fields.value[index]?.type !== 'heading' && (
                      <Field
                        component={TextFieldInput}
                        validate={composeValidators(
                          required,
                          ['href'].includes(fields.value[index]?.type) ? isValidURL : checkIfRawGit
                        )}
                        subtitle={labels.url}
                        aria-label={labels.url}
                        name={`${name}.url`}
                        hideLabel
                        required
                      />
                    )}
                  </div>
                  <DocumentView
                    name={`${name}.documents`}
                    push={push}
                    index={index}
                    count={count}
                    isPlatformApi={isPlatformApi}
                  />
                  <br />
                </div>
              </CollapisbleView>
              <div className={styles.titleWrapper}>
                {(!isParent || (isParent && fields.length > 1)) && (
                  <div className={styles.buttonWrapper}>
                    <Tooltip
                      isDark
                      label={labels.removeDocument}
                      role='tooltip'
                      aria-hidden='true'
                      bodySlot={<div className={styles.tooltip}>{labels.removeDocument}</div>}
                    >
                      <IconButton
                        label={labels.removeDocument}
                        className={styles.removeButton}
                        variant='secondary'
                        size='small'
                        icon='Minus'
                        onClick={() => fields.remove(index)}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          ))
        }
      </FieldArray>
      {isParent && (
        <div className={styles.addNewButton}>
          <Button size='small' variant='secondary' onClick={() => push(name, {})}>
            {getAddButtonLabel(count, 'doc')}
          </Button>
        </div>
      )}
    </div>
  )
}

// API COMPONENTS
function PlatformApiFields({ capabilities }) {
  return (
    <>
      <Field
        component={CapabilitiesSelect}
        options={capabilities || []}
        aria-label={labels.capabilities}
        name='capabilities'
        required
        validate={atLeastOneRequired(labels.capabilitiesError)}
        placeholder={placeholders.capabilities}
      />
      <FieldArray
        name='lifecycle'
        validate={composeValidators(
          (values) => chronologicalOrder(values.map((value) => value.date)),
          (values) =>
            atLeastOneRequired(labels.lifecycleError)(
              values.filter((value) => value.date).map((value) => value.date)
            )
        )}
      >
        {({ fields, meta }) => (
          <>
            <div className={styles.field}>
              <Text font={'title-6'}>
                Lifecycle Status<span className={styles.required}>*</span>
              </Text>
              <Text font={'body-3'} className={styles.subtitle}>
                {labels.lifecycleSubtitle}
              </Text>
              {meta.error && (meta.dirty || meta.submitFailed) && (
                <Text font={'body-3'} className={styles.error}>
                  {meta.error}
                </Text>
              )}
            </div>
            {fields.map((name) => (
              <div key={name} className={styles.lifecycle}>
                <Field name={name} component={LifecycleDatePicker} />
              </div>
            ))}
          </>
        )}
      </FieldArray>
      <Field
        component={SelectInput}
        options={options.interactionType}
        label={labels.interactionType}
        aria-label={labels.interactionType}
        name='apiInteractionType'
        placeholder={placeholders.interactionType}
        required
        validate={required}
      />
    </>
  )
}

function CapabilitiesSelect({ input, meta, ...rest }) {
  const defaultValue = rest.options?.filter((el) =>
    meta.initial?.map((c) => c.id).includes(el.value)
  )
  const [capabilities, setCapabilities] = useState(defaultValue)

  useEffect(() => {
    if (meta.initial) {
      input.onChange(meta.initial.map((c) => c.id))
    }
  }, [meta.initial])

  return (
    <div className={styles.field}>
      <Select
        {...input}
        {...rest}
        value={capabilities}
        isMulti
        onChange={(selected) => {
          input.onChange(selected.map((c) => c.value))
          setCapabilities(selected)
        }}
        hasErrors={meta.error && meta.touched}
        errorMessage={meta.error}
        label={
          <>
            {labels.capabilities}
            <span className={styles.required}>*</span>
          </>
        }
        defaultValue={defaultValue}
      />
    </div>
  )
}

function LifecycleDatePicker({ input, meta, ...rest }) {
  const { status, date: inputDate } = input.value
  const statusLabel = status.charAt(0).toUpperCase() + status.slice(1)
  const [date, setDate] = useState(inputDate || '')
  return (
    <div className={styles.field}>
      <TextField
        {...rest}
        {...input}
        type={'date'}
        label={statusLabel}
        subtitle={labels.lifecycleStatusSubtitles[status]}
        hasErrors={meta.error && meta.touched}
        errorMessage={meta.error}
        value={date}
        onChange={(e) => {
          const value = e.target.value
          setDate(value)
          input.onChange({
            status,
            date: value ? new Date(value).toISOString() : null,
          })
        }}
      />
    </div>
  )
}

// DEVPORTAL COMPONENTS
function OwnerSelect({ input, meta, ...rest }) {
  const [owner, setOwner] = useState(getSelectedOption(rest.options, input.value))

  useEffect(() => {
    if (meta.initial) {
      input.onChange(meta.initial)
    }
  }, [meta.initial])

  return (
    <div className={styles.field}>
      <Select
        {...input}
        {...rest}
        value={owner}
        onChange={(selected) => {
          input.onChange(selected.value)
          setOwner(selected)
        }}
        label={
          <>
            {labels.owner}
            <span className={styles.required}>*</span>
          </>
        }
        hasErrors={meta.error && meta.touched}
        errorMessage={meta.error}
        defaultValue={meta.initial}
      />
    </div>
  )
}

const getInitialValues = (manifestJSON, isPlatformApi) => {
  let initialValues = {}
  if (manifestJSON) {
    initialValues = {
      ...manifestJSON,
      urls: {
        ...manifestJSON.urls,
        sdk: manifestJSON.urls?.sdk?.length ? manifestJSON.urls.sdk : [undefined],
      },
      versions: manifestJSON.versions?.length
        ? manifestJSON.versions
        : [
            {
              documents: [undefined],
              environments: isPlatformApi ? [undefined] : [],
            },
          ],
      defaultVersionIndex: Math.max(
        0,
        (manifestJSON?.versions || []).findIndex((el) => el.name === manifestJSON.defaultVersion)
      ),
    }
  }

  return initialValues
}

const getProjectDocumentationUrlsSubtitles = (isPlatformApi) => {
  return labels.projectDocumentationUrlsSubtitles(isPlatformApi ? 'API' : 'Project')
}
export default function DocumentationManifestForm({
  onSubmit,
  onCancel,
  tags,
  manifestJSON,
  isPlatformApi,
  capabilities,
  owners,
  loading,
  isNew,
}) {
  const initialValues = useMemo(() => getInitialValues(manifestJSON, isPlatformApi), [manifestJSON])
  return (
    <Form
      key='document-manifest-builder-form'
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        },
      }}
      render={({
        form: {
          mutators: { push, setValue },
        },
        ...props
      }) => (
        <form className={styles.form} onSubmit={props.handleSubmit} key='manifest-builder-form'>
          <div className={styles.content}>
            <Card className={styles.card}>
              <Field
                required
                validate={composeValidators(required, noSpecialChars)}
                component={TextFieldInput}
                label={getConditionalLabel(isPlatformApi, labels.title)}
                aria-label={labels.title}
                name='title'
                maxChars={50}
                disabled={!isNew && initialValues.title} // TODO: allow editable name in future
              />
              <Field
                component={TextAreaInput}
                label={getConditionalLabel(isPlatformApi, labels.description)}
                aria-label={labels.description}
                name='description'
                validate={required}
                required
                minRows={3}
              />
              {!isPlatformApi && owners && owners.length > 0 && (
                <Field
                  component={OwnerSelect}
                  options={owners}
                  name='owner'
                  required
                  validate={composeValidators(required)}
                  placeholder={placeholders.owner}
                />
              )}

              {isPlatformApi && <PlatformApiFields capabilities={capabilities} />}
              <Field
                required
                component={TextFieldInput}
                validate={required}
                label={labels.maintainerEmail}
                aria-label={labels.maintainerEmail}
                name='maintainerEmail'
              />
              {tags?.length > 0 && (
                <Field
                  component={EditableMultiSelectInput}
                  validate={composeValidators(itemSizeLimit(25), maxItems(50))}
                  label={labels.tags}
                  aria-label={labels.tags}
                  name='tags'
                  options={tags}
                  placeholder={placeholders.tags}
                />
              )}
            </Card>
            <Card className={styles.card}>
              <Label font='title-5'>
                {labels.projectDocumentationUrls}{' '}
                <Text as='div' font='body-3' color='text-secondary'>
                  {getProjectDocumentationUrlsSubtitles(isPlatformApi)}
                </Text>
              </Label>
              <Field
                component={TextFieldInput}
                validate={isValidURL}
                label={labels.repositoryUrl}
                aria-label={labels.repositoryUrl}
                name='urls.repository'
              />
              <Field
                component={TextFieldInput}
                validate={isValidURL}
                label={labels.issuesUrl}
                aria-label={labels.issuesUrl}
                name='urls.issues'
              />
              <Field
                component={TextFieldInput}
                validate={isValidURL}
                label={labels.slackUrl}
                aria-label={labels.slackUrl}
                name='urls.slack'
              />
              <Field
                component={TextFieldInput}
                validate={isValidURL}
                label={labels.terraformUrl}
                aria-label={labels.terraformUrl}
                name='urls.terraform'
              />
              <FieldArray name='urls.sdk'>
                {({ fields }) =>
                  fields.map((name, index) => (
                    <div className={styles.sdkUrl} key={name}>
                      <div className={styles.sdkUrlTitle}>
                        <Label font='title-6'>
                          {labels.sdkUrl} #{index + 1}
                        </Label>
                        {fields.length > 1 && (
                          <Tooltip
                            isDark
                            label={labels.removeSdkUrl}
                            role='tooltip'
                            aria-hidden='true'
                            bodySlot={<div className={styles.tooltip}>{labels.removeSdkUrl}</div>}
                          >
                            <IconButton
                              label={labels.removeSdkUrl}
                              className={styles.sdkDeleteButton}
                              variant='secondary'
                              size='small'
                              icon='Minus'
                              onClick={() => fields.remove(index)}
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.sdkField}>
                        <Field
                          hideLabel
                          name={`${name}.title`}
                          component={TextFieldInput}
                          subtitle={labels.sdkUrlName}
                          aria-label={labels.sdkUrlName}
                        />
                      </div>
                      <div className={styles.sdkField}>
                        <Field
                          hideLabel
                          name={`${name}.url`}
                          validate={isValidURL}
                          component={TextFieldInput}
                          subtitle={labels.sdkUrl}
                          aria-label={labels.sdkUrl}
                        />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
              <div className={styles.addNewButton}>
                <Button
                  size='small'
                  variant='secondary'
                  onClick={() => push('urls.sdk', undefined)}
                >
                  {labels.addAnotherSdk}
                </Button>
              </div>
              <Field
                component={TextFieldInput}
                validate={isValidURL}
                label={labels.cliUrl}
                aria-label={labels.cliUrl}
                name='urls.cli'
              />
            </Card>

            <Card className={styles.versions}>
              <Label font='title-5'>{labels.versions}</Label>
              <FieldArray name='versions'>
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <div key={name} className={styles.versionWrapper}>
                        {fields.length > 1 && (
                          <div className={styles.removeVersionBtn}>
                            <Tooltip
                              isDark
                              label={labels.removeVersion}
                              role='tooltip'
                              aria-hidden='true'
                              bodySlot={
                                <div className={styles.tooltip}>{labels.removeVersion}</div>
                              }
                            >
                              <IconButton
                                label={labels.removeVersion}
                                variant='secondary'
                                size='small'
                                icon='Minus'
                                onClick={() => fields.remove(index)}
                              />
                            </Tooltip>
                          </div>
                        )}

                        <Field
                          component={TextFieldInput}
                          validate={required}
                          label={labels.versionName}
                          aria-label={labels.versionName}
                          name={`${name}.name`}
                          required
                        />

                        <div className={styles.checkboxWrapper}>
                          <Checkbox
                            label={labels.defaultVersion}
                            value={`${fields.value[index]?.name}`}
                            onChange={() => {
                              setValue('defaultVersionIndex', index)
                            }}
                            checked={props.values.defaultVersionIndex === index}
                          />
                        </div>
                        <Field
                          component={SelectInput}
                          options={options.versionStatus}
                          label={labels.status}
                          aria-label={labels.status}
                          name={`${name}.status`}
                          placeholder={placeholders.versionStatus}
                        />
                        <EnvironmentView
                          name={`${name}.environments`}
                          push={push}
                          count={fields.value[index]?.environments?.length || 0}
                          isPlatformApi={isPlatformApi}
                        />

                        <DocumentView
                          name={`${name}.documents`}
                          push={push}
                          isParent
                          count={fields.value[index]?.documents?.length}
                          isPlatformApi={isPlatformApi}
                          allDocuments={fields.value[index]?.documents}
                        />

                        {fields.length > 1 && <hr />}
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>

              <div className={styles.addNewButton}>
                <Button
                  size='small'
                  variant='secondary'
                  onClick={() =>
                    push('versions', {
                      documents: manifestJSON?.versions?.documents?.length
                        ? manifestJSON?.versions?.documents
                        : [undefined],
                      environments: manifestJSON?.versions?.environments?.length
                        ? manifestJSON?.versions?.environments
                        : isPlatformApi
                        ? [undefined]
                        : [],
                    })
                  }
                >
                  {labels.addNewVersion}
                </Button>
              </div>
            </Card>
          </div>
          <Buttons {...props} loading={loading} isNew={isNew} isPlatformApi={isPlatformApi} />
        </form>
      )}
    />
  )
}

function ApiHealthField({ field, name }) {
  const [statusIcon, setStatusIcon] = useState({
    isLoading: false,
    response: null,
    hasError: false,
  })
  const [popoverIsVisible, setPopoverIsVisible] = useState(false)
  const handleTest = async (endpoint) => {
    setStatusIcon({ isLoading: true, response: null, hasError: false })
    try {
      const response = await http.get(endpoint, null, {
        'Content-Type': 'application/health+json',
      })
      const result = response ? JSON.parse(JSON.stringify(response)) : null
      setStatusIcon({ isLoading: false, response: result, hasError: false })
    } catch (e) {
      setStatusIcon({ isLoading: false, response: null, hasError: true })
    }
  }

  return (
    <div className={styles.nestedWrapperWithButton}>
      <Field
        hideLabel
        component={TextFieldInput}
        validate={isValidURL}
        subtitle={labels.healthUrl}
        aria-label={labels.healthUrl}
        name={`${name}.healthUrl`}
      />
      <div className={styles.actionIcons}>
        {(statusIcon.response || statusIcon.hasError) && !statusIcon.isLoading && (
          <Popover
            isDark
            placement='left'
            className={'eds-tooltip'}
            isOpen={popoverIsVisible}
            onMouseLeave={() => setPopoverIsVisible(false)}
            onClickOutside={() => setPopoverIsVisible(false)}
            bodySlot={
              <div className={styles.tooltipResponseBody}>
                <span>
                  {statusIcon.response ? (
                    <pre>
                      <code>{JSON.stringify(statusIcon.response || {}, null, 2)}</code>
                    </pre>
                  ) : (
                    <>
                      Health URL might be incorrect. Please check the documentation{' '}
                      <Link
                        href='https://console.platforms.nike.com/developer/docs/projects/platform-console/health-integration'
                        font='body-3'
                        target='_blank'
                      >
                        here
                      </Link>
                    </>
                  )}
                </span>
              </div>
            }
          >
            {statusIcon?.response?.status === 'pass' ? (
              <Icon
                name='CheckCircleFilled'
                color='var(--eds-color-background-success)'
                onMouseEnter={() => setPopoverIsVisible(true)}
              />
            ) : (
              <Icon
                name='AlertCircleFilled'
                color='var(--eds-color-brand-red)'
                onMouseEnter={() => setPopoverIsVisible(true)}
              />
            )}
          </Popover>
        )}
        {statusIcon.isLoading && <Spinner size='large' />}
        {!statusIcon.isLoading && (
          <Button variant='secondary' onClick={() => handleTest(field?.healthUrl || '')}>
            Test
          </Button>
        )}
      </div>
    </div>
  )
}
