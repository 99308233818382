const parseText = (content, link, blankSpaceReplaceText = '-', hyperlinkPrefix = '') => {
  switch (content.type) {
    case 'text':
    case 'inlineCode': {
      const text = content.value
      link.text += text
      link.href += `${hyperlinkPrefix}${text.replace(/\s+/g, blankSpaceReplaceText)}`
      break
    }
    case 'link':
    case 'strong':
    case 'emphasis': {
      if (content.children.length === 0) break
      return parseText(content.children[0], link, blankSpaceReplaceText, hyperlinkPrefix)
    }
    default:
      return link
  }

  return link
}

export default parseText
