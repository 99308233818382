import React, { useCallback, useState, useImperativeHandle } from 'react'
import { Modal, Button, Snackbar, Snack } from '@nike/eds'

const ReportableLink = ({
  contentRef,
  linksContainerRef,
  adminEmail,
  onReportLink,
  isReportEnabled,
  setIsReportEnabled,
  setDisabled,
}) => {
  const [displaySnack, setDisplaySnack] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)

  const linkEvent = useCallback((event) => {
    event.preventDefault()
    event.stopImmediatePropagation()
    onReportLink({
      href: event.currentTarget.href,
      text: event.currentTarget.innerText || event.currentTarget.innerHTML,
      currentPage: window.location.href,
      adminEmail: adminEmail?.current,
    })
    setDisplaySnack(false)
    setDisplayModal(true)
  }, [])

  const cancelReport = useCallback(() => {
    const doc = linksContainerRef.current
    doc.querySelectorAll('a').forEach((link) => {
      link.removeEventListener('click', linkEvent, true)
      link.classList.remove('reportable')
    })
    setIsReportEnabled(false)
    setDisplayModal(false)
    setDisplaySnack(false)
    setDisabled(false)
  }, [linksContainerRef, setIsReportEnabled])

  const reportAction = useCallback(() => {
    if (isReportEnabled) {
      cancelReport()
      return
    }
    const doc = linksContainerRef.current
    setIsReportEnabled(true)
    doc.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', linkEvent, true)
      link.classList.add('reportable')
    })
    setDisplaySnack(true)
  }, [setIsReportEnabled])

  useImperativeHandle(contentRef, () => ({
    reportLink: reportAction,
  }))

  return (
    <>
      <Modal
        headerSlot='Broken link reported'
        footerSlot={<Button onClick={cancelReport}>Close</Button>}
        isOpen={displayModal}
        onDismiss={cancelReport}
      >
        Broken link report email has been sent to {adminEmail?.current}.
      </Modal>
      <Snackbar>
        {displaySnack && (
          <Snack id='1' onDismiss={cancelReport}>
            Click on any broken link* to report it to the document owner. <br /> *You can click only
            links in red boxes. <br /> If you want to cancel this action, close this element
          </Snack>
        )}
      </Snackbar>
    </>
  )
}

export default ReportableLink
