const isTargetBlank = (target) =>
  target.getAttribute('target')?.includes('blank') &&
  target.getAttribute('rel') === 'noopener noreferrer'

export const navigate = (onNavigate) => (e) => {
  const target = e.target
  if (e.target.tagName === 'A' && !isModifiedEvent(e) && !target.hash) {
    e.preventDefault()
    if (target.origin === window.origin && !isTargetBlank(target)) {
      onNavigate(target.pathname)
    } else {
      const win = window.open(target.href, '_blank', 'noopener noreferrer')
      if (win) win.focus()
    }
  }
}

export function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}
