import styles from '../../stylus/docs.styl'
import React, { useRef, useEffect, useState, createContext } from 'react'
import { Link as EdsLink, Spinner, Icon } from '@nike/eds'

import { usePrevious } from '../../util/usePrevious.js'

import { getNodeBySlug } from '../Tree/selectors.js'
import { renderDocType } from './renderDocType.js'
import { useContentCache } from './cache.js'

const navigationHeight = 59

export const DocsContentCtx = createContext()

const DocsContent = ({
  crumbs,
  tree,
  projectName,
  error,
  onNavigate,
  basePath,
  version,
  token,
  docsApi,
  Link,
}) => {
  const [activeSection, setActiveSection] = useState('')
  const slug = crumbs[crumbs.length - 1] === 'guide' ? 'EP Docs' : crumbs[crumbs.length - 1]
  const title =
    crumbs[crumbs.length - 1] === 'guide'
      ? 'EP Docs'
      : getNodeBySlug(tree, crumbs[crumbs.length - 1])?.title || slug

  const contentElRef = useRef()
  const docsContentWrapperRef = useRef()

  const { content, loading, docError, title: docTitle } = useContentCache({
    tree,
    title,
    projectName,
    version,
  })

  // Deal/not with scrolling Content to top
  const prevPath = usePrevious(getPath())
  if (contentElRef && contentElRef.current && getPath() !== prevPath) {
    contentElRef.current.scrollTop = 0
  }

  const errorClassName = `${styles['docs-content']} ${styles.error}`

  useEffect(() => {
    const handleScroll = () => {
      if (content?.raw?.length > 50000) return
      const sections = docsContentWrapperRef.current.querySelectorAll('[id]:is(h1, h2, h3, h4)')
      let closestSectionId = ''

      sections.forEach((section) => {
        const distanceFromTop = section.getBoundingClientRect().top - navigationHeight

        if (distanceFromTop >= 0 && distanceFromTop < 125) {
          closestSectionId = section.id
        }
      })
      if (closestSectionId) setActiveSection(closestSectionId)
    }
    const docsContentWrapper = docsContentWrapperRef.current
    docsContentWrapper.addEventListener('scroll', handleScroll)
    return () => docsContentWrapper.removeEventListener('scroll', handleScroll)
  }, [content])

  return (
    <DocsContentCtx.Provider value={activeSection}>
      <div
        className={styles['docs-content-wrapper']}
        id='docs-content-wrapper'
        ref={docsContentWrapperRef}
      >
        {error || docError ? (
          <div className={errorClassName}>
            <Icon name='AlertCircleFilled' color='var(--eds-color-brand-red)' />
            <div>
              <div>{error || docError}</div>
              <EdsLink as={Link} href={basePath} className={styles.info}>
                Return to the project&apos;s root document
              </EdsLink>
            </div>
          </div>
        ) : loading || (!!title && !content) ? (
          <Loading />
        ) : content ? (
          <>
            {renderDocType({
              content,
              onNavigate,
              basePath,
              errorClassName,
              tree,
              contentElRef,
              token,
              Link,
            })}
          </>
        ) : (
          <div className={errorClassName}>
            <Icon name='AlertCircleFilled' color='var(--eds-color-brand-red)' />
            Document &quot;{slug}&quot; Not Found
          </div>
        )}
      </div>
    </DocsContentCtx.Provider>
  )
}

function Loading() {
  return (
    <div className={`${styles.markdown} ${styles['docs-content']}`}>
      <Spinner className={styles.spinner} size='large' />
    </div>
  )
}

export function getPath() {
  return window.location.pathname
}

export default DocsContent
