import React, { Component } from 'react'

import { parse as apiParse } from '../../util/api-parser.js'
import Error from '../Error/Error.js'
import 'swagger-ui/dist/swagger-ui.css'
import SwaggerUI from 'swagger-ui'
import { ModelCollapseOverride } from './plugins.js'

class SwaggerDocs extends Component {
  constructor(props) {
    super(props)
    this.swaggerContainer = null
    this.ui = null
    this.state = {
      hasLoadCompleted: true,
      error: null,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.documentUrl !== nextProps.documentUrl ||
      this.props.spec !== nextProps.spec ||
      this.state.hasLoadCompleted !== nextState.hasLoadCompleted
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.spec !== prevProps.spec) {
      this.setState({ error: null })
      this.mountSwagger()
    }
  }

  componentDidMount() {
    this.mountSwagger()
  }

  cleanupSwagger = () => {
    while (this.swaggerContainer && this.swaggerContainer.firstChild) {
      this.swaggerContainer.removeChild(this.swaggerContainer.firstChild)
    }
  }

  mountSwagger = async () => {
    this.cleanupSwagger()
    let { spec, onError } = this.props

    try {
      spec = apiParse(spec)
      if (!spec) return
    } catch (e) {
      // If onError supplied, do not supply default error component
      if (onError) {
        onError(e)
      } else {
        this.setState({ error: 'Error Parsing Spec' + e.toString() })
      }
      return
    }

    this.ui = SwaggerUI({
      spec,
      domNode: this.swaggerContainer,
      deepLinking: false,
      plugins: [ModelCollapseOverride],
      presets: [SwaggerUI.presets.apis],
      validatorUrl: null,
    })

    this.ui.initOAuth({
      usePkceWithAuthorizationCodeGrant: true,
    })
  }

  componentWillUnmount() {
    this.cleanupSwagger()
  }

  render() {
    const { error } = this.state
    if (error) return <Error error={error} />
    return (
      <div
        className={`swagger-docs-container ${
          this.state.hasLoadCompleted ? '' : 'invisible'
        } swagger-section`}
      >
        <div
          ref={(node) => {
            this.swaggerContainer = node
          }}
          className='swagger-ui-wrap'
        />
      </div>
    )
  }
}

export default SwaggerDocs
