import { getConfig } from '../config.js'

const sendEmail = (linkData) => ({
  operationName: 'sendEmail',
  variables: {
    subject: 'Broken link report in docs',
    to: linkData.adminEmail,
    body: `A user has reported a broken link on a documentation page that you own. Please review and update the page with current information. <br />
            The broken link is titled, "${linkData.text}" with url "${linkData.href}" and is located on this docs page: <a href='${linkData.currentPage}'>${linkData.currentPage}</a>`,
  },
  query: `
      mutation sendEmail($subject: String!, $to: String!, $body: String!) {
        sendEmail(subject: $subject, to: $to, body: $body)
      }
    `,
})

export const sendEmailRequest = async (linkData, token) => {
  const graphqlEndpoint = getConfig().platformConsoleApi
  const emailData = sendEmail(linkData)
  try {
    const response = await fetch(graphqlEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(emailData),
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error('Error sending email:', error)
  }
}
