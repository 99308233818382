import React, { useEffect, useState, useMemo } from 'react'

import { SidePanel, Button, IconButton, Text, Link, Spinner } from '@nike/eds'

import { fetchProject } from '../../util/devportal'
import { setGlobalAuthHeader } from '../../util/http.js'

import { useContentCache } from '../Content/cache.js'
import { renderDocType } from '../Content/renderDocType.js'

import styles from '../../stylus/contextualHelp.styl'
import docsStyles from '../../stylus/docs.styl'

import { setConfig, getConfig } from '../../config.js'

const eventName = 'CONTEXTUAL_HELP_LOAD_DOC'
const supportedTypes = ['markdown', 'asciidoc']

function MoreLinks({ moreLinks }) {
  return (
    moreLinks?.length > 0 && (
      <div className={styles.moreLinks}>
        <Text font='subtitle-1' className={styles.moreLinksHeader}>
          More Links
        </Text>
        <ul>
          {moreLinks.map(({ title, href }) => (
            <li key={title}>
              <Link href={href} target='_blank'>
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  )
}

function Loading() {
  return (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  )
}

function Error({ error }) {
  return <Text font='subtitle-1'>{error}</Text>
}

function FetchedContent({ visible, projectName, documentName, section, accessToken, moreLinks }) {
  const [projectLoading, setProjectLoading] = useState(false)
  const [project, setProject] = useState(null)
  const [documentTree, setDocumentTree] = useState(null)
  const [rawSection, setRawSection] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const loadProject = async () => {
      if (!projectName) return
      try {
        setProjectLoading(true)
        const project = await fetchProject(projectName)
        setProject(project)
        const projectVersion = project?.versions?.[project?.latestVersion]
        setDocumentTree(projectVersion?.documents)
      } catch (error) {
        setError(error.message)
        console.log(error)
      }
      setProjectLoading(false)
    }

    loadProject()
  }, [projectName])

  const version = project?.latestVersion
  const { content, loading, docError, title: docTitle } = useContentCache({
    tree: documentTree,
    title: documentName,
    projectName,
    version,
  })

  // if document is markdown and user specified a section, only show that section
  // only support markdown for now
  useMemo(() => {
    let rawSection = null
    if (visible && content?.raw && content?.type === 'markdown' && section) {
      // find the section in the markdown content
      const regex = new RegExp(String.raw`(?:^|\n)#+\s${section}[^\n]*\n(.*?)(?=\n#+?\s|$)`, 'gs')
      const matches = content.raw.match(regex) || []
      const found = matches[0]
      if (found) {
        // remove the section title because it is redundant with the side panel title
        rawSection = found.replace(new RegExp(String.raw`(?:^|\n)#+\s${section}[^\n]*\n`), '')
      }
    }
    setRawSection(rawSection)
  }, [projectName, documentName, visible, section, content])

  if (projectLoading) {
    return <Loading />
  }

  return error || docError ? (
    <Error error={error || docError} />
  ) : loading || (!!documentName && !content) ? (
    <Loading />
  ) : !supportedTypes.includes(content?.type) ? (
    <Error error='Content Type Not Supported' />
  ) : (
    content && (
      <RenderContent
        accessToken={accessToken}
        content={{ ...content, raw: rawSection || content.raw }}
        projectName={projectName}
        documentTree={documentTree}
        version={version}
        docTitle={docTitle}
        moreLinks={moreLinks}
      />
    )
  )
}

function Content({ content, ...rest }) {
  return content ? <RenderContent content={content} {...rest} /> : <FetchedContent {...rest} />
}

function RenderContent({ content, projectName, documentTree, accessToken, moreLinks }) {
  const config = getConfig()
  // Contextual help will always open links in a new tab
  function onNavigate(path) {
    const href = `${window.origin}${path}`
    const win = window.open(href, '_blank', 'noopener noreferrer')
    if (win) win.focus()
  }

  return (
    <div>
      <div className={docsStyles['docs-content-wrapper']}>
        {renderDocType({
          content,
          onNavigate,
          basePath: `${config.docProjectBaseUrl}/${projectName}`,
          tree: documentTree,
          token: accessToken,
          Link,
          isContextualHelp: true,
        })}
      </div>
      <MoreLinks moreLinks={moreLinks} />
    </div>
  )
}

export function ContextualHelpTrigger({ documentName, projectName, section, moreLinks, content }) {
  function triggerHelp() {
    const detail = { documentName, projectName, section, moreLinks, content }
    const EventObj = { detail }
    const event = new window.CustomEvent(eventName, EventObj)
    window.dispatchEvent(event)
  }

  return <IconButton icon='QuestionCircle' onClick={triggerHelp} size='small' variant='ghost' />
}

export function ContextualHelp({ accessToken, env }) {
  const [visible, setVisible] = useState(false)
  const [detail, setEventDetail] = useState(null)
  setConfig(env)

  const { projectName, documentName, section, moreLinks, content } = detail || {}

  function dismiss() {
    setVisible(false)
  }

  useEffect(() => {
    setGlobalAuthHeader(accessToken)
  }, [accessToken])

  useEffect(() => {
    function recieveEvent(event) {
      setEventDetail(event.detail)
      setVisible(true)
    }

    window.addEventListener(eventName, recieveEvent)
    return () => window.removeEventListener(eventName, recieveEvent)
  }, [])

  return (
    <SidePanel
      hideFade
      className={styles.contextualHelp}
      onDismiss={dismiss}
      isOpen={visible}
      headerSlot={<Text font='title-2'>{section || documentName}</Text>}
      footerSlot={
        <div className={styles.footer}>
          <Button variant='secondary' onClick={dismiss}>
            Close
          </Button>
        </div>
      }
    >
      <div className={styles.panel}>
        <Content
          visible={visible}
          accessToken={accessToken}
          projectName={projectName}
          documentName={documentName}
          section={section}
          content={content}
          moreLinks={moreLinks}
        />
      </div>
    </SidePanel>
  )
}
