import React from 'react'

// Updates models displayed in the "Schemas" section of the Swagger UI.
//
// Inject line break opportunity, (<wbr>), elements into the names of top-level models at
// periods and forward slash characters. This wraps the text when the window is narrow.
//
// Without this modification, the following would be rendered:
//
// <span class="model-box">
//   <span class="model model-title">
//     odp_deployment_service_rest_api.Models.DeploymentApp
//   </span>
// </span>
//
// With modification, it is:
//
// <span class="model-box">
//   <span class="model model-title">
//     odp_deployment_service_rest_api<wbr>.Models<wbr>.DeploymentApp
//   </span>
// </span>
export const ModelCollapseOverride = () => ({
  wrapComponents: {
    ModelCollapse: (Original) => (props) => {
      if (!props.displayName) {
        return <Original {...props} />
      }
      const displayNameParts = props.displayName.split(/(?=[./])/g)
      for (let i = 1; i < displayNameParts.length; i += 2) {
        displayNameParts.splice(i, 0, <wbr key={i} />)
      }
      const customTitle = (
        <span className='model-box'>
          <span className='model model-title'>{displayNameParts}</span>
        </span>
      )
      return <Original {...Object.assign({}, props, { title: customTitle })} />
    },
  },
})
