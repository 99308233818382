export default {
  tree: {
    base: {
      listStyle: 'none',
      backgroundColor: '#F5F5F5',
      padding: '0 10px',
      color: '#333',
      fontFamily: 'Helvetica Neue',
      fontSize: '14px',
      cursor: 'pointer',
    },
    node: {
      base: {
        position: 'relative',
      },
      link: {
        cursor: 'pointer',
        position: 'relative',
        padding: '5px 5px',
      },
      activeLink: {
        background: '#31363F',
      },
      toggle: {
        base: {
          position: 'relative',
          display: 'inline',
          cursor: 'pointer',
          verticalAlign: 'top',
          marginRight: '10px',
        },
        wrapper: {
          cursor: 'pointer',
          position: 'absolute',
          top: '50%',
          left: '50%',
          margin: '-7px 0 0 -7px',
          height: '14px',
        },
        height: 14,
        width: 14,
        arrow: {
          fill: '#111',
          strokeWidth: 0,
        },
        cursor: 'pointer',
      },
      header: {
        cursor: 'pointer',
        base: {
          display: 'inline-block',
          verticalAlign: 'top',
          color: '#333',
        },
        connector: {
          width: '2px',
          height: '12px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          left: '-21px',
        },
        title: {
          lineHeight: '24px',
          verticalAlign: 'middle',
        },
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: '10px',
      },
      loading: {
        color: '#E2C089',
      },
    },
  },
}
